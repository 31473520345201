const mergeMinMax = (acc, val) => [val[0] === null ? acc[0] : Math.min(acc[0], val[0]), val[1] === null ? acc[1] : Math.max(acc[1], val[1])];
export const getExtremumX = params => {
  const {
    series,
    axis,
    isDefaultAxis,
    getFilters
  } = params;
  return Object.keys(series).filter(seriesId => {
    const axisId = series[seriesId].xAxisId ?? series[seriesId].xAxisKey;
    return axisId === axis.id || axisId === undefined && isDefaultAxis;
  }).reduce((acc, seriesId) => {
    const filter = getFilters?.({
      currentAxisId: axis.id,
      isDefaultAxis,
      seriesXAxisId: series[seriesId].xAxisId ?? series[seriesId].xAxisKey,
      seriesYAxisId: series[seriesId].yAxisId ?? series[seriesId].yAxisKey
    });
    const seriesMinMax = series[seriesId].data?.reduce((accSeries, d, dataIndex) => {
      if (filter && !filter(d, dataIndex)) {
        return accSeries;
      }
      return mergeMinMax(accSeries, [d.x, d.x]);
    }, [Infinity, -Infinity]);
    return mergeMinMax(acc, seriesMinMax ?? [Infinity, -Infinity]);
  }, [Infinity, -Infinity]);
};
export const getExtremumY = params => {
  const {
    series,
    axis,
    isDefaultAxis,
    getFilters
  } = params;
  return Object.keys(series).filter(seriesId => {
    const axisId = series[seriesId].yAxisId ?? series[seriesId].yAxisKey;
    return axisId === axis.id || axisId === undefined && isDefaultAxis;
  }).reduce((acc, seriesId) => {
    const filter = getFilters?.({
      currentAxisId: axis.id,
      isDefaultAxis,
      seriesXAxisId: series[seriesId].xAxisId ?? series[seriesId].xAxisKey,
      seriesYAxisId: series[seriesId].yAxisId ?? series[seriesId].yAxisKey
    });
    const seriesMinMax = series[seriesId].data?.reduce((accSeries, d, dataIndex) => {
      if (filter && !filter(d, dataIndex)) {
        return accSeries;
      }
      return mergeMinMax(accSeries, [d.y, d.y]);
    }, [Infinity, -Infinity]);
    return mergeMinMax(acc, seriesMinMax ?? [Infinity, -Infinity]);
  }, [Infinity, -Infinity]);
};